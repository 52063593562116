import React from "react";
import Layout from "../components/Layout.jsx";
import { useIntl } from "gatsby-plugin-intl";
import { parseXmlServices } from "../utils";
import { graphql } from "gatsby";
import CompanySection from "../components/CompanySection.jsx";

export default ({ data, location }) => {
  const currentLocale = useIntl().locale;
  const services = parseXmlServices(data, currentLocale);
  return (
    <Layout location={location}>
      <CompanySection services={services} />
    </Layout>
  );
};

export const query = graphql`
  query {
    allServicesXml {
      edges {
        node {
          xmlChildren {
            children {
              name
              content
            }
            content
            name
          }
          name
        }
      }
    }
  }
`;
